import React from "react";
import ah from "../img/ah.png";

export let colors = ["rgb(1, 208, 108)", "rgb(5, 186, 199)"];

/**
 * Generates and returns a font awesome
 * icon for the name provided
 * @param {string} name
 * @param {string} [mainClass] the main icon class (fab, fas, fa...)
 */
export const fetchIcon = (name, mainClass = 'fab') => {
  return <i className={`${mainClass} fa-${name}`} aria-hidden="true" />
};

export const info = {
  firstName: "Adam",
  lastName: "Hanafi",
  initials: "AH",
  positions: ["Software Engineer", "Real estate Investor", "Developer", "Car Enthusiast"],
  selfPortrait: ah,
  gradient: `linear-gradient(135deg, ${colors})`,
  miniBio: [
    ["💼", "Chief Tech Officer at WebNovare"],
    ["⌚", "Coffee Afficianado"],
    ["🇺🇸", "Based in New York City"],
    ["✉️", "develop@webnovare.com"],
  ],
  socials: [
    ["https://linkedin.com/in/adam-hanafi", fetchIcon('linkedin', 'fa')],
    ["https://instagram.com/webequity", fetchIcon('instagram')],
  ],
  bio:
    "Hello! I'm Adam Hanafi. I'm an enthusiast. I reside in New York City, and I am passionate about developing secure, scalable, and accessible tech. I'm also passionate about photography, classic literature, art, investing, cars, and advocating for gun rights.",
  hobbies: [
    ["🛖", "developing"],
    ["📖", "reading"],
    ["🏎️", "cars"],
    ["✍", "writing"],
    ["🧑‍💻", "networking"],
  ],
  portfolio: [
    {
      title: "Bloomberg Terminal",
      liveLink: "#",
      sourceLink: "#",
    },
    {
      title: "EPIC medical billing",
      liveLink: "#",
      sourceLink: "#",
    },
    {
      title: "Fortnite",
      liveLink: "#",
      sourceLink: "#",
    },
    {
      title: "Spotify",
      liveLink: "#",
      sourceLink: "https://github.com/AdamHanafi/spotify-info",
    },
  ],
};
