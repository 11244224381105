import React from "react";
import Style from "./Portfolio.module.scss";
import friendly from "../img/friendly.jpg";
import skeleton from "../img/skeleton.png";
import indus from "../img/indus.jpg";
import spotify from "../img/spotify.jpg";
import { info, colors } from "../Info/Info";

export default function Portfolio() {
  let portfolioInfo = info.portfolio;

  let mocks = ["https://bs-uploads.toptal.io/blackfish-uploads/components/seo/content/og_image_file/og_image/1285825/1113-BloombergTerminal-Waldek_Social-14462098a967aff72da3fcdfb5181b3c.png", "https://www.emrsystems.net/wp-content/uploads/2016/09/Epic-EMR-Software-Patient-Summary-Overview.jpg", "https://miro.medium.com/v2/resize:fit:2000/1*OSVptnNeegbjhnSWo2V_qw.jpeg", spotify];

  function createGrid() {
    let results = [];
    let j = 0;
    for (let i = 0; i < portfolioInfo.length; i++) {
      results.push(
        <div key={i} className={Style.block}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={info.portfolio[i].liveLink}
          >
            <img
              alt="Project screenshot"
              className={Style.image}
              src={mocks[j++]}
            />
          </a>
          <h1>{info.portfolio[i].title}</h1>
          {i !== 2 && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={info.portfolio[i].sourceLink}
              style={{ backgroundColor: colors[1] }}
              className={Style.source}
            >
              View
            </a>
          )}
        </div>
      );
    }
    return results;
  }

  return (
    <main>
      <div className={Style.projectsGrid}>{createGrid()}</div>
    </main>
  );
}
